import React from 'react';
import {Result} from 'views';
import Minimal from 'layouts/Minimal';
import WithLayout from 'layouts/WithChildrenLayout';
import {SEO} from "components/seo";


const ResultPage = () => {
  // Translation temporarily disabled
  //const {t} = useTranslation();
  const t = (txt)=>txt;
  return (
    <WithLayout layout={Minimal}>
      <SEO title={t("Thanks!")} />
      <Result
        title={t("Thanks!")}
        message={t("Thanks!")}
        back_message={t("Back home")}
        back_href={"/"}
      />
    </WithLayout>
  )
};

export default ResultPage;